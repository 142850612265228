import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MIME_TYPES = {
    WEBP: 'image/webp',
};

export const Image = forwardRef(({
    webpFormat,
    src: originalSrc,
    ...remainingProps
}, ref) => {
    const enhancedSrc = webpFormat && `${originalSrc}?fm=webp`;

    return (
        <picture>
            { enhancedSrc && <source srcSet={ enhancedSrc } type={ MIME_TYPES.WEBP } /> }
            <source srcSet={ originalSrc } />
            <img
                { ...remainingProps }
                ref={ ref }
                src={ originalSrc } />
        </picture>
    );
});

Image.propTypes = {
    webpFormat: PropTypes.bool,
    src: PropTypes.string.isRequired,
};

Image.defaultProps = {
    webpFormat: true,
};

export default Image;
