import { useContext } from 'react';

import StateContext from '../state/StateContext';

const useContacts = () => {
    const { contacts } = useContext(StateContext) ?? {};

    return {
        ...contacts,
    };
};

export default useContacts;
