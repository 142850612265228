import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Circle } from '../icons';

import styles from './InteractiveCircle.module.css';

export const InteractiveCircle = ({ className, children }) => {
    const containerClasses = classNames(styles.container, className);

    return (
        <div className={ containerClasses }>
            <Circle className={ styles.circle } />
            { children }
        </div>
    );
};

InteractiveCircle.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default InteractiveCircle;
