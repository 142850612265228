import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { SLIDE_TRANSITION_DURATION } from '../../constants';
import ContenfulImage from '../../../contentful-image';

import styles from './ProjectSlide.module.css';

const classNamesMapping = {
    appear: styles.enter,
    appearActive: styles.enterActive,
    appearDone: styles.enterDone,
    enter: styles.enter,
    enterActive: styles.enterActive,
    enterDone: styles.enterDone,
    exit: styles.exit,
    exitActive: styles.exitActive,
    exitDone: styles.exitDone,
};

export const ProjectSlide = ({
    title,
    photo,
    visible,
    className,
    description,
    ...remainingProps
}) => {
    const containerClasses = classNames(styles.container, className);

    return (
        <CSSTransition
            appear
            mountOnEnter
            unmountOnExit
            in={ visible }
            classNames={ classNamesMapping }
            timeout={ SLIDE_TRANSITION_DURATION }>
            <div className={ containerClasses } { ...remainingProps }>
                <ContenfulImage
                    { ...photo }
                    className={ styles.image } />
                { visible && <div className={ styles.overlay } /> }
                <div className={ styles.topInfoContainer }>
                    <div className={ styles.titleWrapper }>
                        <h2 className={ styles.title }>{ title }</h2>
                    </div>
                </div>
                <span className={ styles.separator } />
                <div className={ styles.bottomInfoContainer }>
                    <div className={ styles.descriptionWrapper }>
                        <p className={ styles.description }>{ description }</p>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

ProjectSlide.propTypes = {
    visible: PropTypes.bool, // Injected automatically by Carousel component
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    photo: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
    }).isRequired,
};

ProjectSlide.defaultProps = {
    visible: true,
};

export default ProjectSlide;
