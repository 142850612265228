export const navigationMapper = {
    home: '/',
    about: '/about/',
    projects: '/projects/',
    contacts: '/contacts/',
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
};

export const navigations = [
    {
        id: 'home',
        translationKey: 'home',
    },
    {
        id: 'about',
        translationKey: 'about',
    },
    {
        id: 'projects',
        translationKey: 'projects',
    },
    {
        id: 'contacts',
        translationKey: 'contacts',
    },
];

export const legalNavigations = [
    {
        id: 'privacyPolicy',
        translationKey: 'privacyPolicy',
    },
    {
        id: 'termsAndConditions',
        translationKey: 'termsAndConditions',
    },
];
