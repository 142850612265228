import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { useNavigation } from '@moxy/react-navigation';
import { navigationMapper } from '../../../../data';

import { Logo } from '../../../icons';
import HamburguerButton from '../hamburguer-button';
import Navigations from '../navigations';

import styles from './NavbarContent.module.css';

export const NavbarContent = ({
    variant,
    className,
    navigations,
}) => {
    const { drawer } = useNavigation();

    const contentClasses = classNames(styles.content, styles[variant], className);

    return (
        <div className={ contentClasses }>
            <Link to={ navigationMapper.home }>
                <Logo className={ styles.logo } />
            </Link>
            <Navigations
                items={ navigations }
                linkClassName={ styles.link }
                className={ styles.navigations }
                listItemClassName={ styles.linkWrapper }
                selectedClassName={ styles.selectedLink } />
            <HamburguerButton
                open={ drawer.isOpen }
                openDrawer={ drawer.open }
                closeDrawer={ drawer.close }
                toggleDrawer={ drawer.toggle }
                className={ styles.menuButton } />
        </div>
    );
};

NavbarContent.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string.isRequired,
    navigations: PropTypes.array.isRequired,
};

export default NavbarContent;
