import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useCarousel, useTranslations } from '../../../../hooks';
import { Arrow } from '../../../icons';
import { SLIDE_TRANSITION_DURATION } from '../../constants';

import styles from './Controls.module.css';

const classNamesMapping = {
    enter: styles.enter,
    enterActive: styles.enterActive,
    exit: styles.exit,
    exitActive: styles.exitActive,
};

export const Controls = ({ items, className }) => {
    const {
        prevItem,
        nextItem,
        nextIndex,
    } = useCarousel();

    const isSlideReady = useRef(false);
    const { next } = useTranslations();

    const containerClasses = classNames(styles.container, className);
    const nextProjName = items[nextIndex].title;

    const blockCarouselTransitions = useCallback(() => {
        setTimeout(() => {
            isSlideReady.current = true;
        }, SLIDE_TRANSITION_DURATION);
    }, []);

    const handleArrowClick = useCallback((action) => () => {
        if (isSlideReady.current) {
            isSlideReady.current = false;

            action();
            blockCarouselTransitions();
        }
    }, [blockCarouselTransitions]);

    const endListener = useCallback((node, done) => {
        node.addEventListener('transitionend', done, false);
    }, []);

    useEffect(() => {
        isSlideReady.current = true;
    }, []);

    return (
        <div className={ containerClasses }>
            <div className={ styles.leftContent }>
                <span className={ styles.next }>{ next }</span>
                <SwitchTransition>
                    <CSSTransition
                        key={ nextIndex }
                        addEndListener={ endListener }
                        classNames={ classNamesMapping }>
                        <div className={ styles.nameWrapper }>
                            <p className={ styles.name }>{ nextProjName }</p>
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <div className={ styles.rightContent }>
                <button
                    className={ styles.leftArrowButton }
                    onClick={ handleArrowClick(prevItem) }>
                    <Arrow className={ styles.leftArrow } />
                </button>
                <button
                    className={ styles.rightArrowButton }
                    onClick={ handleArrowClick(nextItem) }>
                    <Arrow className={ styles.rightArrow } />
                </button>
            </div>
        </div>
    );
};

Controls.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
};

export default Controls;
