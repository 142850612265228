import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withPrefix } from 'gatsby';

import { navigationMapper } from '../../../../data';
import useLocation from '../../../layout/useLocation';
import useTranslations from '../../../../hooks/translations';

import styles from './Navigations.module.css';

export const Navigations = ({
    items,
    className,
    linkClassName,
    transitionDelay,
    listItemClassName,
    selectedClassName,
}) => {
    const translations = useTranslations();

    const { pathname } = useLocation();

    return (
        useMemo(() => (
            <ul className={ classNames(styles.list, className) }>
                { items.map(({ id, translationKey }, index) => {
                    const itemTransitionDelay = transitionDelay ?
                        { transitionDelay: `${(index + 1) * transitionDelay}ms` } :
                        undefined;

                    const splittedPathname = pathname.split('/');

                    splittedPathname.pop();

                    const subfolder = `${splittedPathname.join('/')}/`;
                    const isSelected = subfolder === withPrefix(navigationMapper[id]);

                    return (
                        <li
                            style={ itemTransitionDelay }
                            key={ `navigation-${index}` }
                            className={ classNames(styles.listItem, listItemClassName) }>
                            <Link
                                to={ navigationMapper[id] }
                                className={ classNames(
                                    styles.link,
                                    { [selectedClassName]: isSelected },
                                    linkClassName,
                                ) }>
                                { translations[translationKey] }
                            </Link>
                        </li>
                    );
                }) }
            </ul>
        ), [
            items,
            pathname,
            className,
            translations,
            linkClassName,
            transitionDelay,
            listItemClassName,
            selectedClassName,
        ])
    );
};

Navigations.propTypes = {
    className: PropTypes.string,
    linkClassName: PropTypes.string,
    items: PropTypes.array.isRequired,
    transitionDelay: PropTypes.number,
    listItemClassName: PropTypes.string,
    selectedClassName: PropTypes.string,
};

Navigations.defaultProps = {
    transitionDelay: 0,
};

export default Navigations;
