// Add normalize.css as global style
import 'normalize.css';
import './src/shared/styles/index.css';
import '@moxy/react-carousel/dist/styles.css';

import React from 'react';
import { RootElementWrapper } from './src/shared/components';

export const wrapRootElement = ({ element }) => (
    <RootElementWrapper>
        { element }
    </RootElementWrapper>
);
