import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoBlock.module.css';

export const InfoBlock = ({
    text,
    title,
    ...props
}) => (
    <div { ...props }>
        <p className={ styles.title }>{ title }</p>
        <p className={ styles.text }>{ text }</p>
    </div>
);

InfoBlock.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default InfoBlock;
