import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { StateProvider } from '../../hooks/state';
import { ViewportProvider } from '../../hooks/viewport';

const query = graphql`
    query GenericAppInfo {
        contentful {
            genericTranslationsCollection(limit: 1) {
              items {
                requestBudget
                seeProjects
                next
                home
                about
                projects
                contacts
                termsAndConditions
                privacyPolicy
                complaintsBook
                call
                budget
                seeProject
                completedProjects
                client
                year
                location
                status
                address
                phone
                fax
                backToHome
                ceCertification
                showroom
                mission
                goal
                aboutUs
              }
            }
            contactsCollection(limit: 1) {
              items {
                mainEmail
                budgetEmail
                phone
                fax
                address {
                  lat
                  lon
                }
                addressText
              }
            }
            footerCollection(limit: 1) {
              items {
                copyright
                socialNetworksCollection(limit: 10) {
                  items {
                    name
                    link
                  }
                }
              }
            }
        }
    }
`;

export const RootElementWrapper = ({ children }) => {
    const {
        contentful: {
            footerCollection: { items: [footer] },
            contactsCollection: { items: [contacts] },
            genericTranslationsCollection: { items: [translations] },
        },
    } = useStaticQuery(query);

    return (
        <ViewportProvider>
            <StateProvider
                footer={ footer }
                contacts={ contacts }
                translations={ translations }>
                { children }
            </StateProvider>
        </ViewportProvider>
    );
};

RootElementWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default RootElementWrapper;
