import React from 'react';
import { Link } from 'gatsby';

import { Button } from '../../components';
import { useFooter, useTranslations } from '../../hooks';
import { navigationMapper, legalNavigations } from '../../data';

import styles from './Footer.module.css';

const currentYear = new Date().getFullYear();
const complaintsBookUrl = 'https://www.livroreclamacoes.pt/inicio';

export const Footer = () => {
    const translations = useTranslations();
    const {
        copyright,
        socialNetworksCollection,
    } = useFooter();

    const copyrightText = `${currentYear} ${copyright}`;

    const renderedSocialNetworks = socialNetworksCollection?.items?.map(({ name, link }, index) => (
        <li
            key={ `${name}-${index}` }
            className={ styles.socialNetworkItem }>
            <Button newTab to={ link }>
                { `${name}.` }
            </Button>
        </li>
    ));

    const renderedLegalNavigations = legalNavigations.map(({ id, translationKey }, index) => (
        <li
            key={ `legal-nav-${index}` }
            className={ styles.legalNavigationItem }>
            <Link
                to={ navigationMapper[id] }
                className={ styles.legalLink }>
                { `${translations[translationKey]}.` }
            </Link>
        </li>
    ));

    const complaintsBookNavigation = (
        <li
            key={ 'legal-nav-complaints-book' }
            className={ styles.legalNavigationItem }>
            <Button newTab to={ complaintsBookUrl }>
                { `${translations.complaintsBook}.` }
            </Button>
        </li>
    );
    const finalLegalNavigations = [...renderedLegalNavigations, complaintsBookNavigation];

    return (
        <footer className={ styles.footer }>
            <ul className={ styles.socialNetworksList }>
                { renderedSocialNetworks }
            </ul>
            <ul className={ styles.legalPagesList }>
                { finalLegalNavigations }
            </ul>
            <span className={ styles.copyright }>
                { copyrightText }
            </span>
        </footer>
    );
};

export default Footer;
