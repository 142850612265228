import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ProjectsSet from '../projects-set';

import styles from './ProjectsGrid.module.css';

export const ProjectsGrid = ({ projects }) => {
    const renderedSets = useMemo(() => {
        const initAcc = {
            setProjects: [],
            setVariant: 'normal',
            renderedSets: [],
        };

        const { renderedSets } = projects.reduce((acc, project, index) => {
            const isMultiple = (index + 1) % 3 === 0;
            const isLastProject = index === projects.length - 1;

            acc.setProjects.push({
                slug: project.slug,
                title: project.title,
                location: project.address,
                imageUrl: project.photosCollection.items[0].url,
                imageAlt: project.photosCollection.items[0].description,
            });

            if (isMultiple || isLastProject) {
                const projects = [...acc.setProjects];

                acc.renderedSets.push(
                    <ProjectsSet
                        projects={ projects }
                        className={ styles.set }
                        variant={ acc.setVariant }
                        key={ `projects-set-${index}` } />,
                );

                acc.setVariant = acc.setVariant === 'normal' ? 'inverted' : 'normal';
                acc.setProjects = [];
            }

            return acc;
        }, initAcc);

        return renderedSets;
    }, [projects]);

    return (
        <>
            { renderedSets }
        </>
    );
};

ProjectsGrid.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        smallDescription: PropTypes.string.isRequired,
        photosCollection: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                url: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            })).isRequired,
        }).isRequired,
    })).isRequired,
};

export default ProjectsGrid;
