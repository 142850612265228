import { useContext } from 'react';

import CarouselContext from './CarouselContext';

const useCarousel = () => {
    const value = useContext(CarouselContext);

    return {
        ...value,
    };
};

export default useCarousel;
