import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeyboardOutlinesOnly from '@moxy/react-keyboard-only-outlines';

import Seo from '../seo/Seo';
import Header from '../header';
import Footer from '../footer';
import FullHeight from '../full-height';
import LocationContext from './useLocation/context';

import styles from './Layout.module.css';

const HEADER_VARIANT = {
    WHITE: 'white',
    TRANSPARENT: 'transparent',
};

export const Layout = ({
    seoData,
    children,
    location,
    className,
    withFooter,
    fullHeight,
    headerVariant,
}) => {
    const Wrapper = fullHeight ? FullHeight : Fragment;
    const pageClasses = classNames(styles.page, {
        [styles.fullHeight]: fullHeight,
    });

    return (
        <KeyboardOutlinesOnly>
            <LocationContext.Provider value={ location }>
                <Wrapper>
                    <div className={ pageClasses }>
                        <Seo
                            title={ seoData.title }
                            image={ seoData.image }
                            path={ location.pathname }
                            article={ seoData.article }
                            description={ seoData.description } />
                        <Header variant={ headerVariant } />
                        <main className={ classNames(styles.main, className) }>
                            { children }
                        </main>
                        { withFooter && <Footer /> }
                    </div>
                </Wrapper>
            </LocationContext.Provider>
        </KeyboardOutlinesOnly>
    );
};

Layout.propTypes = {
    seoData: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string,
        article: PropTypes.bool,
        image: PropTypes.string,
        description: PropTypes.string,
    }),
    fullHeight: PropTypes.bool,
    withFooter: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    headerVariant: PropTypes.oneOf(Object.values(HEADER_VARIANT)),
};

Layout.defaultProps = {
    seoData: {},
    withFooter: true,
    fullHeight: false,
    headerVariant: HEADER_VARIANT.WHITE,
};

export default Layout;
