import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import templateMapping from './template-mapping';
import StateContext from './StateContext';

export const StateProvider = ({
    footer,
    children,
    contacts,
    translations,
}) => {
    const state = useMemo(() => {
        const finalTranslations = Object.entries(translations).reduce((acc, [key, value]) => {
            // Find template strings. E.g.: {currentYear}
            const match = value.match(/\{(.*?)\}/);

            if (!match) {
                acc[key] = value;

                return acc;
            }

            const [keywordWithBrackets, keyword] = match;

            acc[key] = value.replace(keywordWithBrackets, templateMapping[keyword]);

            return acc;
        }, {});

        return ({
            footer,
            contacts,
            translations: finalTranslations,
        });
    }, [translations, contacts, footer]);

    return (
        <StateContext.Provider value={ state }>
            { children }
        </StateContext.Provider>
    );
};

StateProvider.propTypes = {
    children: PropTypes.node.isRequired,
    footer: PropTypes.object.isRequired,
    contacts: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
};

export default StateProvider;
