import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ScaleAndFadeAnimation.module.css';

export const ScaleAndFadeAnimation = ({
    children,
    className,
    startAnimation,
}) => {
    const containerClasses = classNames(styles.container, className);
    const elementClasses = classNames(
        styles.element,
        { [styles.visible]: startAnimation },
        children.props.className,
    );

    return (
        <div className={ containerClasses }>
            { cloneElement(children, { className: elementClasses }) }
        </div>
    );
};

ScaleAndFadeAnimation.propTypes = {
    className: PropTypes.string,
    startAnimation: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

ScaleAndFadeAnimation.defaultProps = {
    startAnimation: false,
};

export default ScaleAndFadeAnimation;
