import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Observer from '../../../observer';
import { navigationMapper } from '../../../../data';
import SplittedButton from '../../../splitted-button';
import ContentfulImage from '../../../contentful-image';
import { useTranslations } from '../../../../hooks';

import styles from './ProjectCard.module.css';

const RATIO = {
    BIG: 'big',
    SMALL: 'small',
};

const TEXT_LOCATION = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
};

const TEXT_ALIGNMENT = {
    START: 'start',
    END: 'end',
};

export const ProjectCard = ({
    slug,
    title,
    ratio,
    imageUrl,
    imageAlt,
    location,
    className,
    textLocation,
    textAlignment,
    imageClassName,
}) => {
    const { seeProject } = useTranslations();
    const projectPath = `${navigationMapper.projects}${slug}`;

    const imageContainerClasses = classNames(styles.imageContainer, imageClassName);
    const imageWrapperClasses = classNames(styles.imageWrapper, styles[ratio]);
    const containerClasses = classNames(
        styles.container,
        styles[textLocation],
        styles[textAlignment],
        className,
    );

    return (
        <Observer threshold={ 0.1 }>
            { (isVisible) => (
                <div className={ classNames(containerClasses, { [styles.visible]: isVisible }) }>
                    <div className={ styles.info }>
                        <p className={ styles.title }>{ title }</p>
                        <p className={ styles.location }>{ location }</p>
                    </div>
                    <Link
                        to={ projectPath }
                        className={ imageContainerClasses }>
                        <div className={ imageWrapperClasses }>
                            <ContentfulImage
                                alt={ imageAlt }
                                src={ imageUrl }
                                className={ styles.image } />
                        </div>
                        <SplittedButton
                            className={ styles.button }
                            topTextClassName={ styles.buttonText }
                            separatorClassName={ styles.separator }
                            bottomTextClassName={ styles.buttonText }>
                            { seeProject }
                        </SplittedButton>
                    </Link>
                </div>
            ) }
        </Observer>
    );
};

ProjectCard.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    ratio: PropTypes.oneOf(Object.values(RATIO)),
    textLocation: PropTypes.oneOf(Object.values(TEXT_LOCATION)),
    textAlignment: PropTypes.oneOf(Object.values(TEXT_ALIGNMENT)),
};

ProjectCard.defaultProps = {
    ratio: RATIO.BIG,
    textLocation: TEXT_LOCATION.LEFT,
    textAlignment: TEXT_ALIGNMENT.START,
};

export default ProjectCard;
