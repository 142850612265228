import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './Button.module.css';

const Button = ({
    to,
    newTab,
    children,
    asAnchor,
    className,
    ...rest
}) => {
    if (to) {
        const classes = classNames(styles.link, className);

        return newTab || asAnchor ?
            (
                <a
                    href={ to }
                    rel="noopener noreferrer"
                    target={ newTab ? '_blank' : undefined }
                    className={ classNames(classes, styles.anchor) }>
                    { children }
                </a>
            ) :
            (
                <Link
                    to={ to }
                    className={ classes }>
                    { children }
                </Link>
            );
    }

    return (
        <button
            className={ classNames(styles.button, className) }
            { ...rest }>
            { children }
        </button>
    );
};

Button.propTypes = {
    to: PropTypes.string,
    newTab: PropTypes.bool,
    asAnchor: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Button.defaultProps = {
    newTab: false,
    asAnchor: false,
};

export default Button;
