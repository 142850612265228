import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../button';

import styles from './SplittedButton.module.css';

export const SplittedButton = ({
    children,
    className,
    withSeparator,
    topTextClassName,
    separatorClassName,
    bottomTextClassName,
    ...remainingProps
}) => {
    const buttonClasses = classNames(styles.button, className);
    const separatorClasses = classNames(styles.separator, separatorClassName);
    const topTextClasses = classNames(styles.topText, topTextClassName);
    const bottomTextClasses = classNames(styles.bottomText, bottomTextClassName);

    const { topText, bottomText } = useMemo(() => {
        const words = children?.split(' ') ?? [];

        if (words.length === 1) {
            return {
                topText: words[0],
            };
        }

        const middleIndex = Math.floor(words.length / 2);

        return {
            topText: words.slice(0, middleIndex).join(' '),
            bottomText: words.slice(middleIndex).join(' '),
        };
    }, [children]);

    return (
        <Button className={ buttonClasses } { ...remainingProps }>
            <span className={ topTextClasses }>{ topText }</span>
            { withSeparator && <span className={ separatorClasses } /> }
            <span className={ bottomTextClasses }>{ bottomText }</span>
        </Button>
    );
};

SplittedButton.propTypes = {
    className: PropTypes.string,
    withSeparator: PropTypes.bool,
    topTextClassName: PropTypes.string,
    separatorClassName: PropTypes.string,
    bottomTextClassName: PropTypes.string,
    children: PropTypes.string.isRequired,
};

SplittedButton.defaultProps = {
    withSeparator: true,
};

export default SplittedButton;
