import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavigationProvider, Navbar, Drawer } from '@moxy/react-navigation';

import FullHeight from '../full-height';
import { navigations } from '../../data';
import { useScrollPosition } from '../../hooks';
import { NavbarContent, DrawerContent } from './components';

import styles from './Header.module.css';

const HEADER_HEIGHT = 90;

export const Header = (props) => {
    const { current, direction } = useScrollPosition();
    const [state, setState] = useState({
        fixed: false,
        visible: false,
        withAnimation: false,
    });

    const setStateObj = (obj) => setState((prevState) => ({
        ...prevState,
        ...obj,
    }));

    useEffect(() => {
        if (direction.y === 'up') {
            setStateObj(current.y <= 0 ? {
                fixed: false,
                visible: false,
                withAnimation: false,
            } : {
                visible: true,
                withAnimation: true,
            });
        } else if (direction.y === 'down') {
            if (state.fixed) {
                setStateObj({ visible: false });
            } else if (current.y >= HEADER_HEIGHT) {
                setStateObj({ fixed: true });
            }
        }
    }, [current.y, direction.y, state.fixed]);

    const hasSolidBackground = state.fixed && current.y > 0;
    const variant = hasSolidBackground ? 'white' : props.variant;

    const headerClasses = classNames(
        styles.header,
        styles[direction.y],
        {
            [styles.fixed]: state.fixed,
            [styles.withAnimation]: state.withAnimation,
        },
    );
    const navbarClasses = classNames(
        styles.navbar,
        styles[variant],
        { [styles.visible]: state.visible },
    );

    return (
        <header className={ headerClasses }>
            <NavigationProvider>
                <Navbar
                    placement="top"
                    className={ navbarClasses }>
                    <NavbarContent
                        variant={ variant }
                        navigations={ navigations } />
                    <Drawer
                        placement="left"
                        withOverlay={ false }
                        className={ styles.drawer }>
                        <FullHeight>
                            <DrawerContent
                                navigations={ navigations }
                                className={ styles.drawerContent }
                                actionsClassName={ styles.drawerActions }
                                logoWrapperClassName={ styles.logoWrapper }
                                navigationsClassName={ styles.drawerNavigations } />
                        </FullHeight>
                    </Drawer>
                </Navbar>
            </NavigationProvider>
        </header>
    );
};

Header.propTypes = {
    variant: PropTypes.string.isRequired,
};

export default Header;
