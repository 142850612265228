import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Logo } from '../../../icons';
import Navigations from '../navigations';
import { useTranslations, useContacts } from '../../../../hooks';
import { navigationMapper } from '../../../../data';

import styles from './DrawerContent.module.css';

const TRANSITION_DELAY = 150;

export const DrawerContent = ({
    className,
    navigations,
    actionsClassName,
    logoWrapperClassName,
    navigationsClassName,
}) => {
    const contacts = useContacts();
    const translations = useTranslations();

    const containerClasses = classNames(styles.container, className);
    const navigationClasses = classNames(styles.navigations, navigationsClassName);
    const actionsClasses = classNames(styles.actions, actionsClassName);
    const logoWrapperClasses = classNames(styles.wrapper, logoWrapperClassName);

    return (
        <div className={ containerClasses }>
            <div className={ styles.bar }>
                <div className={ logoWrapperClasses }>
                    <Link to={ navigationMapper.home }>
                        <Logo className={ styles.logo } />
                    </Link>
                </div>
            </div>
            <Navigations
                items={ navigations }
                linkClassName={ styles.link }
                className={ navigationClasses }
                transitionDelay={ TRANSITION_DELAY }
                listItemClassName={ styles.linkWrapper }
                selectedClassName={ styles.selectedLink } />
            <div className={ actionsClasses }>
                <a className={ styles.call } href={ `tel:${contacts.phone}` }>
                    <span>{ translations.call }</span>
                </a>
                <a className={ styles.budget } href={ `mailto:${contacts.budgetEmail}` }>
                    <span>{ translations.budget }</span>
                </a>
            </div>
        </div>
    );
};

DrawerContent.propTypes = {
    className: PropTypes.string,
    actionsClassName: PropTypes.string,
    navigationsClassName: PropTypes.string,
    logoWrapperClassName: PropTypes.string,
    navigations: PropTypes.array.isRequired,
};

export default DrawerContent;
