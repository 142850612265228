import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useCarousel } from '../../hooks';

import styles from './Carousel.module.css';

export const Carousel = ({
    items,
    className,
}) => {
    const { oldIndex, activeIndex } = useCarousel();
    const containerClasses = classNames(styles.container, className);

    // Avoids flicker on first render
    const isVisible = typeof window !== 'undefined';

    return (
        <div className={ containerClasses }>
            { oldIndex !== undefined &&
                cloneElement(items[oldIndex], {
                    ...items[oldIndex].props,
                    visible: false,
                    className: classNames(items[oldIndex].props.className, styles.old),
                })
            }
            { cloneElement(items[activeIndex], {
                ...items[activeIndex].props,
                visible: isVisible,
                className: classNames(items[activeIndex].props.className, styles.new),
            }) }
        </div>
    );
};

Carousel.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Carousel;
