import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FullHeight.module.css';

export const FullHeight = ({ className, children }) => {
    const classes = classNames(styles.container, className);

    return (
        <div className={ classes }>
            { children }
        </div>
    );
};

FullHeight.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default FullHeight;
