import { useContext } from 'react';

import StateContext from '../state/StateContext';

const useFooter = () => {
    const { footer } = useContext(StateContext) ?? {};

    return {
        ...footer,
    };
};

export default useFooter;
