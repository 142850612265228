import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ProjectCard } from './components';

import styles from './ProjectsSet.module.css';

const INDEX_MAPPING = {
    normal: {
        0: {
            className: styles.topProject,
            imageClassName: styles.topImage,
        },
        1: {
            ratio: 'small',
            textLocation: 'bottom',
            className: styles.centerProject,
            imageClassName: styles.centerImage,
        },
        2: {
            textAlignment: 'end',
            className: styles.bottomProject,
            imageClassName: styles.bottomImage,
        },
    },
    inverted: {
        0: {
            textLocation: 'right',
            imageClassName: styles.topImage,
            className: classNames(styles.topProject, styles.inverted),
        },
        1: {
            ratio: 'small',
            textAlignment: 'end',
            textLocation: 'bottom',
            imageClassName: styles.centerImage,
            className: classNames(styles.centerProject, styles.inverted),
        },
        2: {
            textAlignment: 'end',
            textLocation: 'right',
            imageClassName: styles.bottomImage,
            className: classNames(styles.bottomProject, styles.inverted),
        },
    },
};

const VARIANT = {
    NORMAL: 'normal',
    INVERTED: 'inverted',
};

export const ProjectsSet = ({
    variant,
    projects,
    className,
}) => {
    if (projects?.length > 3) {
        throw new Error('ProjectsSet component expects an array with length of <= 3');
    }

    const setClasses = classNames(styles.set, className);

    const renderedProjects = projects.map((project, index) => {
        const props = {
            ...INDEX_MAPPING[variant][index],
            ...project,
        };

        return (
            <ProjectCard
                key={ `project-card-${index}` }
                { ...props } />
        );
    });

    return (
        <div className={ setClasses }>
            { renderedProjects }
        </div>
    );
};

ProjectsSet.propTypes = {
    className: PropTypes.string,
    projects: PropTypes.array.isRequired,
    variant: PropTypes.oneOf(Object.values(VARIANT)),
};

ProjectsSet.defaultProps = {
    variant: VARIANT.NORMAL,
};

export default ProjectsSet;
