import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SplittedButton from '../splitted-button';
import InteractiveCircle from '../interactive-circle';
import { useTranslations, useContacts } from '../../hooks';
import { Arrow } from '../icons';

import styles from './BudgetButton.module.css';

export const BudgetButton = ({
    className,
    arrowClassName,
    splittedButtonClassName,
}) => {
    const contacts = useContacts();
    const { requestBudget } = useTranslations();

    const buttonClasses = classNames(styles.circle, className);
    const arrowClasses = classNames(styles.arrow, arrowClassName);
    const splittedButtonClasses = classNames(styles.splittedButton, splittedButtonClassName);

    return (
        <InteractiveCircle className={ buttonClasses }>
            <Arrow className={ arrowClasses } />
            <div className={ styles.buttonContainer }>
                <SplittedButton
                    asAnchor
                    withSeparator={ false }
                    className={ splittedButtonClasses }
                    to={ `mailto:${contacts.budgetEmail}` }>
                    { requestBudget }
                </SplittedButton>
            </div>
        </InteractiveCircle>
    );
};

BudgetButton.propTypes = {
    className: PropTypes.string,
    arrowClassName: PropTypes.string,
    splittedButtonClassName: PropTypes.string,
};

export default BudgetButton;
